.button-1 {
  padding: 2px 24px;
  border-radius: 40px;
}

.bot-item-card {
  margin-bottom: 15px;
}

/* .bot-item-card .MuiCard-root { */
/* border-radius: 4px; */
/*background: #fff;*/
/* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
/* } */

.card-btn-padding {
  padding: 10px 20px;
  padding-top: 0px;
}

.text-center {
  text-align: center;
}

.logo-section.text-center {
  padding-bottom: 130px;
}

.logo-section.text-center a img {
  margin: 0 auto;
  margin-bottom: 24px;
}
.MuiDialog-paper {
  box-shadow: none !important;
}

/* mui formcontrollabel full width */
/* .css-1vbggne-MuiFormControlLabel-root .MuiFormControlLabel-label {
    flex: 1;
} */

.chat-suggested-text {
  column-gap: 8px;
}
.chat-suggested-text span {
  font-size: 13px;
  padding: 5px 10px;
  margin: 5px 0;
}

.logo-section.text-center a img {
  margin-bottom: 15px;
}

.Mui-disabled {
  cursor: not-allowed !important;
}

.test {
  width: 14px;
  height: 14px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e4efef;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #1d388f61;
  border-radius: 2px;
}
.text-cut {
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
